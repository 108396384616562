// extracted by mini-css-extract-plugin
export var container = "Connections-module--container--e9cae";
export var contentContainer = "Connections-module--content-container--d836e";
export var contentInfo = "Connections-module--content-info--c489e";
export var contentTitle = "Connections-module--content-title--affd6";
export var contentWrapper = "Connections-module--content-wrapper--12f3d";
export var flowItemContainer = "Connections-module--flow-item-container--26253";
export var flowItemDescription = "Connections-module--flow-item-description--6e714";
export var flowItemTitle = "Connections-module--flow-item-title--58886";
export var flowItemTitleIcon = "Connections-module--flow-item-title-icon--07c59";
export var howUsingFeature = "Connections-module--how-using-feature--9ed89";
export var howUsingFeatureContent = "Connections-module--how-using-feature-content--b391b";
export var howUsingFeaturesList = "Connections-module--how-using-features-list--7f7cc";
export var howUsingFlow = "Connections-module--how-using-flow--52c7d";
export var howUsingFlowItem = "Connections-module--how-using-flow-item--d3e11";
export var howUsingIcon = "Connections-module--how-using-icon--e4188";
export var howUsingTitleTablet = "Connections-module--how-using-title-tablet--5af11";
export var sectionTitle = "Connections-module--section-title--7aa2b";