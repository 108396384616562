import React from "react"
import { Link } from "gatsby-plugin-react-i18next"
import { useTranslation } from "gatsby-plugin-react-i18next"
import cx from "classnames"

import MOCK_DATA from "./SERVICES_MOCK_DATA"

import * as styles from "./Services.module.css"

const Services = () => {
  const { t } = useTranslation()

  return (
    <section className={cx(styles.wrapper, "container")}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>{t("Услуги")}</h2>
        <div className={styles.items}>
          {MOCK_DATA.map((item, index) => (
            <div key={index} className={styles.item}>
              <div className={styles.itemImage}>
                <img src={item.icon} alt="" />
              </div>
              <div className={styles.itemContent}>
                <span className={styles.itemTitle}>{t(item.title)}</span>
                <p className={styles.itemDescription}>{t(item.description)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.moreWrapper}>
        <Link to="/services/" className="theme-button-text">
          {t("Подробнее")}
        </Link>
      </div>
    </section>
  )
}
export default Services
