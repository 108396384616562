import React from "react"
import cx from "classnames"
import * as styles from "./Connections.module.css"
import OnlineBills from "../OnlineBills"
import HowUseOnlineBills from "./HowUseOnlineBills"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Connections = () => {
  const { t } = useTranslation()
  return (
    <section className={cx(styles.container, "container")}>
      <h2 className={styles.sectionTitle}>{t("Подключения")}</h2>
      <div className={styles.contentWrapper}>
        <HowUseOnlineBills />
        <OnlineBills />
      </div>
    </section>
  )
}

export default Connections
