// extracted by mini-css-extract-plugin
export var container = "Services-module--container--74b0f";
export var item = "Services-module--item--526f9";
export var itemContent = "Services-module--item-content--8e49c";
export var itemDescription = "Services-module--item-description--708da";
export var itemImage = "Services-module--item-image--a7a50";
export var itemTitle = "Services-module--item-title--e3edf";
export var items = "Services-module--items--a1ac1";
export var moreWrapper = "Services-module--more-wrapper--47ad0";
export var sectionTitle = "Services-module--section-title--08df6";
export var wrapper = "Services-module--wrapper--a3fc7";