import React from "react"
import * as styles from "./Connections.module.css"
import cx from "classnames"
import IconBasket from "../../assets/images/Icon.Basket.svg"
import IconUserId from "../../assets/images/Icon.UserId.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"

const HowUseOnlineBills = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.contentContainer}>
      <h3 className={styles.contentTitle}>
        {t("Как мы используем online-чеки")}
      </h3>
      <div className={styles.contentInfo}>
        <div className={styles.howUsingFeaturesList}>
          <h3 className={styles.howUsingTitleTablet}>
            {t("Как мы используем online-чеки")}
          </h3>
          <div className={styles.howUsingFeature}>
            <div className={styles.howUsingIcon}>
              <img src={IconBasket} alt={""} />
            </div>
            <div className={styles.howUsingFeatureContent}>
              <p className="text-subheader">{t("Online транзакции")}</p>
              <p className="text-standart">
                {t("полная детализация по SKU и месту покупки")}
              </p>
            </div>
          </div>

          <div className={styles.howUsingFeature}>
            <div className={styles.howUsingIcon}>
              <img src={IconUserId} alt={""} />
            </div>
            <div className={styles.howUsingFeatureContent}>
              <p className="text-subheader">{t("Единый ID покупателя")}</p>
              <p className="text-standart">
                {t("по всем интернет-магазинам и маркетплейсам")}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.howUsingFlow}>
          <div className={styles.howUsingFlowItem}>
            <div className={styles.flowItemContainer}>
              <div className={styles.flowItemTitle}>
                <span className={cx("text-h1", styles.flowItemTitleIcon)}>
                  &gt;
                </span>
                <span className="text-subheader">{t("Как получаем")}</span>
              </div>
              <p className={cx("text-standart", styles.flowItemDescription)}>
                {t(
                  "Через подключение к данным ККТ (контрольно-кассовые терминалы обслуживающие маркетплейсы)"
                )}
              </p>
            </div>
          </div>
          <div className={styles.howUsingFlowItem}>
            <div className={styles.flowItemContainer}>
              <div className={styles.flowItemTitle}>
                <span className={cx("text-h1", styles.flowItemTitleIcon)}>
                  &gt;
                </span>
                <span className="text-subheader">{t("Что видим")}</span>
              </div>
              <p className={cx("text-standart", styles.flowItemDescription)}>
                {t("Состав корзины покупателя (SKU, сумма и время покупки)")}
              </p>
            </div>
          </div>
          <div className={styles.howUsingFlowItem}>
            <div className={styles.flowItemContainer}>
              <div className={styles.flowItemTitle}>
                <span className={cx("text-h1", styles.flowItemTitleIcon)}>
                  &gt;
                </span>
                <span className="text-subheader">{t("Применение")}</span>
              </div>
              <p className={cx("text-standart", styles.flowItemDescription)}>
                {t(
                  "Анализ потребительских привычек пользователя и вычисление вероятности следующей покупки"
                )}
              </p>
            </div>
          </div>
          <div className={styles.howUsingFlowItem}>
            <div className={styles.flowItemContainer}>
              <div className={styles.flowItemTitle}>
                <span className={cx("text-h1", styles.flowItemTitleIcon)}>
                  &gt;
                </span>
                <span className="text-subheader">{t("Как распознаём")}</span>
              </div>
              <p className={cx("text-standart", styles.flowItemDescription)}>
                {t(
                  "Определяем магазин и категорию на основе предиктивной модели"
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HowUseOnlineBills
